import React from 'react';

class WireView extends React.Component {
        constructor(props) {
                super(props);

                this.state = {
                        points: [0,0,0,0,
                                 0,0,0,0,
                                 0,0,0,0]
                };
        }

        generateAutoWireLeftOrRightPosition(isLeft) {
                var module1X = this.props.pt1.module ? this.props.pt1.module.x : this.props.pt1.x;
                var module2X = this.props.pt2.module ? this.props.pt2.module.x : this.props.pt2.x;

                var koef = 20 + Math.min(Math.abs(this.props.pt1.x - module1X),Math.abs(this.props.pt2.x - module2X))/5;

                if (Math.abs(this.props.pt1.directiony) < 0.00001 && Math.abs(this.props.pt1.directionx) < 0.00001) {
                        this.props.pt1.directiony = Math.sign(this.props.pt2.y - this.props.pt1.y);
                }
                if (Math.abs(this.props.pt2.directiony) < 0.00001 && Math.abs(this.props.pt2.directionx) < 0.00001) {
                        this.props.pt2.directiony = Math.sign(this.props.pt1.y - this.props.pt2.y);
                }
                
                this.state.points[0] = this.props.pt1.x;
                this.state.points[1] = this.props.pt1.y;
        
                this.state.points[10] = this.props.pt2.x;
                this.state.points[11] = this.props.pt2.y;

                this.state.points[2] = this.state.points[0];
                this.state.points[3] = this.state.points[1] + this.props.pt1.directiony * koef;
        
                this.state.points[8] = this.state.points[10];
                this.state.points[9] = this.state.points[11] + this.props.pt2.directiony * koef;
        
                this.state.points[5] = this.state.points[3];
                this.state.points[7] = this.state.points[9];
        
                if (Math.abs(this.state.points[3] - this.state.points[9]) < 0.01) {
                        this.state.points[4] = (this.state.points[2] + this.state.points[8]) * 0.5;
                        this.state.points[6] = (this.state.points[2] + this.state.points[8]) * 0.5;
                } else {
                        this.state.points[4] = isLeft ? this.props.min.x + 13 : this.props.max.x - 13;
                        this.state.points[6] = this.state.points[4];
                }
                this.state.points[1] = this.state.points[1] + this.props.pt1.directiony * 17;
                this.state.points[11] = this.state.points[11] + this.props.pt2.directiony * 17;
        }
        
        generateAutoWireDirectPosition() {
                var module1X = this.props.pt1.module ? this.props.pt1.module.x : this.props.pt1.x;
                var module2X = this.props.pt2.module ? this.props.pt2.module.x : this.props.pt2.x;

                var koef = 20 + Math.min(Math.abs(this.props.pt1.x - module1X),Math.abs(this.props.pt2.x - module2X))/5;

                if (Math.abs(this.props.pt1.directiony) < 0.00001 && Math.abs(this.props.pt1.directionx) < 0.00001) {
                        this.props.pt1.directiony = Math.sign(this.props.pt2.y - this.props.pt1.y);
                }
                if (Math.abs(this.props.pt2.directiony) < 0.00001 && Math.abs(this.props.pt2.directionx) < 0.00001) {
                        this.props.pt2.directiony = Math.sign(this.props.pt1.y - this.props.pt2.y);
                }

                this.state.points[0] = this.props.pt1.x;
                this.state.points[1] = this.props.pt1.y;
        
                this.state.points[10] = this.props.pt2.x;
                this.state.points[11] = this.props.pt2.y;

                this.state.points[2] = this.state.points[0];
                this.state.points[3] = this.state.points[1] + this.props.pt1.directiony * koef;
        
                this.state.points[8] = this.state.points[10];
                this.state.points[9] = this.state.points[11] + this.props.pt2.directiony * koef;
        
        
                if ((this.props.pt1.directiony > 0 && this.props.pt2.directiony > 0) ||
                        (this.props.pt1.directiony < 0 && this.props.pt2.directiony < 0)) {
                                this.state.points[4] = this.state.points[2];
                                this.state.points[6] = this.state.points[8];
                } else if ((this.state.points[3] < this.state.points[9] && this.props.pt1.directiony < 0) ||
                        (this.state.points[3] > this.state.points[9] && this.props.pt1.directiony > 0)) {
                                this.state.points[4] = (this.state.points[0] + this.state.points[10]) / 2;
                                this.state.points[6] = this.state.points[4];
        
                                this.state.points[3] = this.state.points[1] + this.props.pt1.directiony * koef;
                                this.state.points[9] = this.state.points[11] + this.props.pt2.directiony * koef;
                } else if (this.props.pt1.directionx != 0) {
                        this.state.points[4] = (this.state.points[0] + this.state.points[10]) / 2;
                        this.state.points[6] = this.state.points[4];
                } else {
                        this.state.points[4] = this.state.points[2];
                        this.state.points[6] = this.state.points[8];
                }
        
                if ((this.props.pt1.directiony < 0 && this.props.pt2.directiony < 0)) {
                        this.state.points[5] = Math.min(this.state.points[3], this.state.points[9]);
                        this.state.points[7] = Math.min(this.state.points[9], this.state.points[3]);
                } else if (this.props.pt1.directiony > 0 && this.props.pt2.directiony > 0) {
                        this.state.points[5] = Math.max(this.state.points[3], this.state.points[9]);
                        this.state.points[7] = Math.max(this.state.points[9], this.state.points[3]);
                } else if ((this.state.points[3] < this.state.points[9] && this.props.pt1.directiony > 0) ||
                        (this.state.points[3] > this.state.points[9] && this.props.pt1.directiony < 0)) {
                                this.state.points[5] = (this.state.points[3] + this.state.points[9]) / 2;
                                this.state.points[7] = (this.state.points[3] + this.state.points[9]) / 2;
                } else {
                        this.state.points[5] = this.state.points[3];
                        this.state.points[7] = this.state.points[9];
                }
                this.state.points[1] = this.state.points[1] + this.props.pt1.directiony * 17;
                this.state.points[11] = this.state.points[11] + this.props.pt2.directiony * 17;
        }

        render() {
                if (Number(this.props.alignment) === 3 && this.props.controlPoints) {
                        this.state.points = this.props.controlPoints;
                } else if (Number(this.props.alignment) === 0) {
                        this.generateAutoWireDirectPosition();
                } else {
                        this.generateAutoWireLeftOrRightPosition(Number(this.props.alignment) === 1);
                }

                if (this.props.propagatePointsUp) {
                        this.props.propagatePointsUp(this.state.points, this.props.index);
                }

                let result = "";
                for (let i=0; i<12; i+=2) {
                        result = result + this.state.points[i] + "," + this.state.points[i+1] + " ";
                }

                var isX1 = Math.abs(this.state.points[3] - this.state.points[5]) > 0.01;
                var isX2 = Math.abs(this.state.points[5] - this.state.points[7]) > 0.01;
                var isX3 = Math.abs(this.state.points[7] - this.state.points[9]) > 0.01;

                return (
                        <g id={this.props.id}>
                                <polyline points={result} 
                                        fill="none" stroke={"rgb(" + this.props.color[0] * 120 + "," +  this.props.color[1] * 120 + "," + this.props.color[2] * 120 + ")"} 
                                        strokeWidth="4" stroke-linecap="round" stroke-linejoin="round">
                                        {this.props.selected ? <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" /> : ""}
                                </polyline>

                                <polyline points={result} 
                                        fill="none" stroke={"rgb(" + this.props.color[0] * 160 + "," +  this.props.color[1] * 160 + "," + this.props.color[2] * 160 + ")"} 
                                        strokeWidth="3" stroke-linecap="round" stroke-linejoin="round"/>
                                <polyline points={result} 
                                        fill="none" stroke={"rgb(" + this.props.color[0] * 200 + "," +  this.props.color[1] * 200 + "," + this.props.color[2] * 200 + ")"} 
                                        strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <polyline points={result} 
                                        fill="none" stroke={"rgb(" + this.props.color[0] * 255 + "," +  this.props.color[1] * 255 + "," + this.props.color[2] * 255 + ")"} 
                                        strokeWidth="1" stroke-linecap="round" stroke-linejoin="round"/>

                                <line id={this.props.id} isX={isX1.toString()} point1={isX1 ? 2 : 3} point2={isX1 ? 4 : 5} 
                                        x1={this.state.points[2]} y1={this.state.points[3]} x2={this.state.points[4]} y2={this.state.points[5]} 
                                        stroke="black" stroke-opacity="0%" 
                                        style={isX1 ? {cursor : "ew-resize"} : {cursor : "ns-resize"}} strokeWidth="4"/>
                                <line id={this.props.id} isX={isX2.toString()} point1={isX2 ? 4 : 5} point2={isX2 ? 6 : 7} 
                                        x1={this.state.points[4]} y1={this.state.points[5]} x2={this.state.points[6]} y2={this.state.points[7]} 
                                        stroke="black" stroke-opacity="0%" 
                                        style={isX2 ? {cursor : "ew-resize"} : {cursor : "ns-resize"}} strokeWidth="4"/>
                                <line id={this.props.id} isX={isX3.toString()} point1={isX3 ? 6 : 7} point2={isX3 ? 8 : 9} 
                                        x1={this.state.points[6]} y1={this.state.points[7]} x2={this.state.points[8]} y2={this.state.points[9]} 
                                        stroke="black" stroke-opacity="0%" 
                                        style={isX3 ? {cursor : "ew-resize"} : {cursor : "ns-resize"}} strokeWidth="4"/>

                                <line x1={this.state.points[0]} y1={this.props.pt1.y} 
                                      x2={this.state.points[0]} y2={this.props.pt1.y + this.props.pt2.directiony * 7} 
                                      stroke="rgb(170,170,170)" strokeWidth="4"/>
                                <line x1={this.state.points[10]} y1={this.props.pt2.y} 
                                      x2={this.state.points[10]} y2={this.props.pt2.y + this.props.pt2.directiony * 7} 
                                      stroke="rgb(170,170,170)" strokeWidth="4"/>
                                <line x1={this.state.points[0]} y1={this.props.pt1.y} 
                                      x2={this.state.points[0]} y2={this.props.pt1.y + this.props.pt2.directiony * 7} 
                                      stroke="rgb(190,190,190)" strokeWidth="3"/>
                                <line x1={this.state.points[10]} y1={this.props.pt2.y} 
                                      x2={this.state.points[10]} y2={this.props.pt2.y + this.props.pt2.directiony * 7} 
                                      stroke="rgb(190,190,190)" strokeWidth="3"/>
                                <line x1={this.state.points[0]} y1={this.props.pt1.y} 
                                      x2={this.state.points[0]} y2={this.props.pt1.y + this.props.pt2.directiony * 7} 
                                      stroke="rgb(210,210,210)" strokeWidth="2"/>
                                <line x1={this.state.points[10]} y1={this.props.pt2.y} 
                                      x2={this.state.points[10]} y2={this.props.pt2.y + this.props.pt2.directiony * 7} 
                                      stroke="rgb(210,210,210)" strokeWidth="2"/>

                                <line x1={this.state.points[0]} y1={this.props.pt1.y + this.props.pt1.directiony * 7} 
                                      x2={this.state.points[0]} y2={this.props.pt1.y + this.props.pt1.directiony * 17} 
                                      stroke={"rgb(" + this.props.color[0] * 120 + "," +  this.props.color[1] * 120 + "," + this.props.color[2] * 120 + ")"} strokeWidth="6"/>
                                <line x1={this.state.points[10]} y1={this.props.pt2.y + this.props.pt2.directiony * 7} 
                                      x2={this.state.points[10]} y2={this.props.pt2.y + this.props.pt2.directiony * 17} 
                                      stroke={"rgb(" + this.props.color[0] * 120 + "," +  this.props.color[1] * 120 + "," + this.props.color[2] * 120 + ")"} strokeWidth="6"/>
                                <line x1={this.state.points[0]} y1={this.props.pt1.y + this.props.pt1.directiony * 7} 
                                      x2={this.state.points[0]} y2={this.props.pt1.y + this.props.pt1.directiony * 17} 
                                      stroke={"rgb(" + this.props.color[0] * 160 + "," +  this.props.color[1] * 160 + "," + this.props.color[2] * 160 + ")"} strokeWidth="4"/>
                                <line x1={this.state.points[10]} y1={this.props.pt2.y + this.props.pt2.directiony * 7} 
                                      x2={this.state.points[10]} y2={this.props.pt2.y + this.props.pt2.directiony * 17} 
                                      stroke={"rgb(" + this.props.color[0] * 160 + "," +  this.props.color[1] * 160 + "," + this.props.color[2] * 160 + ")"} strokeWidth="4"/>
                                <line x1={this.state.points[0]} y1={this.props.pt1.y + this.props.pt1.directiony * 7} 
                                      x2={this.state.points[0]} y2={this.props.pt1.y + this.props.pt1.directiony * 17} 
                                      stroke={"rgb(" + this.props.color[0] * 200 + "," +  this.props.color[1] * 200 + "," + this.props.color[2] * 200 + ")"} strokeWidth="2"/>
                                <line x1={this.state.points[10]} y1={this.props.pt2.y + this.props.pt2.directiony * 7} 
                                      x2={this.state.points[10]} y2={this.props.pt2.y + this.props.pt2.directiony * 17} 
                                      stroke={"rgb(" + this.props.color[0] * 200 + "," +  this.props.color[1] * 200 + "," + this.props.color[2] * 200 + ")"} strokeWidth="2"/>

                        </g>
                );
        }
 
}

export default WireView;