import React from 'react';
import { inject } from 'mobx-react';

var SaveFile = inject("mainStore") (
  class SaveFile extends React.Component {

    constructor(props) {
        super(props);

        this.onFileSave = this.onFileSave.bind(this);

        this.mainStore = this.props.mainStore;

        this.state = {
            imgName:"save_white.png"
        };
    }

    onMouseOver() {
        this.setState({imgName:"save_black.png"});
    }

    onMouseOut() {
        this.setState({imgName:"save_white.png"});
    }

    onFileSave() {
        var wires = JSON.parse(JSON.stringify(this.mainStore.wires));
        var modules = JSON.parse(JSON.stringify(this.mainStore.modules));

        wires.forEach((wire)=>{
            modules.forEach((module)=>{
                module.cleats.forEach((cleat)=>{
                    if(cleat.id===wire.pt1.id) {
                        cleat.direction = Array(wire.pt1.directionx ? wire.pt1.directionx:0, wire.pt1.directiony ? -wire.pt1.directiony:0);
                    } 
                    if (cleat.id===wire.pt2.id) {
                        cleat.direction = Array(wire.pt2.directionx ? wire.pt2.directionx:0, wire.pt2.directiony ? -wire.pt2.directiony:0);
                    }
                })
            })
            wire.pt1.cleatId = wire.pt1.id;
            delete wire.pt1.module;
            delete wire.pt1.x;
            delete wire.pt1.y;
            delete wire.pt1.directionx;
            delete wire.pt1.directiony;
            delete wire.pt1.id;
            wire.pt2.cleatId = wire.pt2.id;
            delete wire.pt2.module;
            delete wire.pt2.x;
            delete wire.pt2.y;
            delete wire.pt2.directionx;
            delete wire.pt2.directiony;
            delete wire.pt2.id;
        });

        modules.forEach((module)=>{
            module.cleats.forEach((cleat)=>{
                cleat.x=parseFloat(cleat.x);
                cleat.y=-cleat.y
            })
            module.size = Array(parseFloat(module.sx), parseFloat(module.sy));

            module.position = Array(module.x + module.sx/2, - module.y - module.sy/2, parseFloat(module.z));

            delete module.x;
            delete module.y;
            delete module.z;
            delete module.sx;
            delete module.sy;
        });
        
        const url = window.URL.createObjectURL(new Blob(["{\"connectors\":", JSON.stringify(wires),
                                                        ", \"modules\":", JSON.stringify(modules), "}",
                                                        ]), {type:'application/json'});
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'electro.schema');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    render(){
        return <img src={this.state.imgName} onMouseOver={()=>this.onMouseOver()} onMouseOut={()=>this.onMouseOut()}
            onClick={this.onFileSave} title="Save" width="48px" height="48px" style={{cursor : "pointer"}}></img>
    }
})

export default SaveFile;
  