import React from 'react';

class BusbarView extends React.Component {
        constructor(props) {
                super(props);

                // this.state = {
                //         points: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                // };
        }

        render() {
                var dincount = Math.round(Math.abs(this.props.pt1.x - this.props.pt2.x)/18.0);
                var directionx = Math.sign(this.props.pt2.x - this.props.pt1.x);
                var directiony = Math.abs(this.props.pt1.directiony) > 0.00001 ? Math.sign(this.props.pt1.directiony) : Math.sign(this.props.pt2.y - this.props.pt1.y);

                return (
                        <g id={this.props.id}>
                                <line id={this.props.id} 
                                        x1={this.props.pt1.x - directionx * 9} y1={this.props.pt1.y + 17.0 * directiony} 
                                        x2={this.props.pt1.x + directionx * dincount * 18.0 + directionx * 9} y2={this.props.pt1.y + 17.0 * directiony} 
                                        stroke={"rgb(220,220,220)"} strokeWidth="16"/>

                                {
                                        Array(parseInt(this.props.pole)).fill(1).map((value, idx)=>{
                                            return (<line id={this.props.id} 
                                            x1={this.props.pt1.x - directionx * 9} y1={this.props.pt1.y + (12.0 + 3 * idx) * directiony} 
                                            x2={this.props.pt1.x + directionx * dincount * 18.0 + directionx * 9} y2={this.props.pt1.y + (12.0 + 3 * idx) * directiony} 
                                            stroke={"rgb(" + this.props.color[0] * 255 + "," +  this.props.color[1] * 255 + "," + this.props.color[2] * 255 + ")"} strokeWidth="2"/>);
                                        })
                                }
                                {
                                        Array(parseInt(dincount + 1)).fill(1).map((value, idx)=>{
                                            return (<line id={this.props.id} 
                                            x1={this.props.pt1.x + directionx * 18 * idx} y1={this.props.pt1.y + 10 * directiony} 
                                            x2={this.props.pt1.x + directionx * 18 * idx} y2={this.props.pt1.y - 5 * directiony} 
                                            stroke={"rgb(184,115,51)"} strokeWidth="4"/>);
                                        })
                                }
                        </g>
                );
        }
 
}

export default BusbarView;