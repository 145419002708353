import React from 'react'
import OpenFile from "./OpenFile.js"
import SaveFile from "./SaveFile.js"
import SavePng from "./SavePng.js"
import ToolbarModuleView from './views/ToolbarModuleView.js'
import ToolbarConnectorView from './views/ToolbarConnectorView.js'
import { inject } from 'mobx-react';

var ToolbarView = inject("mainStore", "uiStore") (
  class ToolbarView extends React.Component {

    constructor(props) {
        super(props);

        this.mainStore = this.props.mainStore;
        this.uiStore = this.props.uiStore;

        this.onConnectionModeChange = this.onConnectionModeChange.bind(this);

        this.state = {
            connectionMode: this.uiStore.connectionMode
        }
    }

    onConnectionModeChange(event) {
        this.uiStore.connectionMode=event.target.value;
        this.setState({connectionMode: this.uiStore.connectionMode});
    }

    render() {
        return (
          <div>
            <OpenFile/>
            <SaveFile/>
            <SavePng/>
            <fieldset style={{width:"180px"}}>
                <legend>Тип соединения</legend>
                <input type="radio" id="rdbtnWire" name="radio-default" onChange={this.onConnectionModeChange} 
                    checked={this.state.connectionMode!="Busbar"} class="rdbtnConnectionMode" value="Wire"/>
                <label class="wire" for="rdbtnWire">Wire</label>

                <input type="radio" id="rdbtnBusbar" name="radio-default" onChange={this.onConnectionModeChange} 
                    checked={this.state.connectionMode=="Busbar"} class="rdbtnConnectionMode" value="Busbar"/>
                <label class="busbar" for="rdbtnBusbar">Busbar</label>
            </fieldset>
            {this.props.currentModuleSelected >=0 ?
                <ToolbarModuleView module = {this.mainStore.modules[this.mainStore.currentModuleSelected]}/>
            : ""}
            {this.props.currentWireSelected >= 0 && this.mainStore.wires && this.mainStore.wires.length > 0 ?
                <ToolbarConnectorView wire={this.mainStore.wires[this.mainStore.currentWireSelected.index]}/>
                :""
            }
          </div>
        )
    }
})

export default ToolbarView;