import { makeObservable, observable, action } from "mobx"

class UiStore {
    
    boxPopup = observable({show: false, index:-1, rowcount: 1, dincount: 1});
    
    openPopup =  observable({show: false});

    offsetX = 0;
    offsetY = 0;
    scale = 1.0;

    lastModuleSelectedColor = [1,1,1,1];
    lastWireSelectedColor = [0,0,1,1];

    connectionMode = "Wire";

    refreshFlag = true;

    constructor() {
        makeObservable(this, {
            refreshFlag: observable,
            refresh: action,
        })
    }

    refresh() {
        this.refreshFlag = !this.refreshFlag;
    }
}

const uiStore = new UiStore();

export default uiStore;