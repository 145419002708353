import React from 'react';

class ItemView extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      item: ''
    };
  }

  onDragStart(event) {
    console.log("onDragStart " + this.state.item.name);

    var img = new Image();
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    event.dataTransfer.setDragImage(img, 0, 0);

    var svg = document.querySelector('svg');
    svg.setAttributeNS(null, "data-x", JSON.stringify(this.state.item));
  }

  render() {
      return (
        <div draggable="true" onDragStart={(e)=>this.onDragStart(e)}>
          <img src={"catalog/" + this.state.item.preview} width="64px" height="64px" draggable="true" style={{margin:"10px",marginBottom:"0px",marginTop:"0px"}}/>
          <p draggable="true" style={{textAlign:"center", marginTop:"0px", marginBottom:"0px"}}><small>{this.state.item.name}</small></p>
        </div>
      );
  }

  componentDidMount() {
    console.log(this.props.path);
    fetch(this.props.path)
    .then(response => response.json())
    .then(data => {
      this.setState({ item: data });
    });
  }
 
}

export default ItemView;