import React from 'react';
import ToolbarInModuleView from "./ToolbarInModuleView.js"
import { inject } from 'mobx-react';

var ToolbarModuleView = inject("mainStore", "uiStore")(
    class ToolbarModuleView extends React.Component {

    constructor(props) {
        super(props);

        this.mainStore = this.props.mainStore;
        this.uiStore = this.props.uiStore;

        this.onSelectedText = this.onSelectedText.bind(this);
        this.onSelectedColor = this.onSelectedColor.bind(this);
    }

    onSelectedText (event) {
        this.setState({moduleSelectedText: event.target.value});
        if (this.mainStore.currentModuleSelected >= 0 && this.mainStore.modules[this.mainStore.currentModuleSelected].texts) {
            this.mainStore.modules[this.mainStore.currentModuleSelected].textIndex = this.mainStore.modules[this.mainStore.currentModuleSelected].texts.findIndex((txt)=>txt.text===event.target.value);
            this.uiStore.refresh();
        }
    }

    onSelectedColor (event) {
        this.setState({moduleSelectedColor: event.target.value});
        if (this.mainStore.currentModuleSelected >= 0) {
            var color = JSON.parse(event.target.value);
            this.mainStore.modules[this.mainStore.currentModuleSelected].color = color;
            this.uiStore.lastModuleSelectedColor = color;
            this.uiStore.refresh();
        }
    }

    render() {
        return (
            <div>
            {this.props.module.catalog === "IN" ?
                <ToolbarInModuleView module = {this.props.module}/>
            :
            <fieldset>
                <legend>Свойства модуля</legend>
                <label>Номинал:
                    <select onChange={this.onSelectedText} name="ModuleText" id="moduleTextSelect" style={{width:"140px",margin:1} } 
                        value={this.props.module && this.props.module.textIndex && this.props.module.texts && this.props.module.textIndex>=0 ? 
                        this.props.module.texts[this.props.module.textIndex].text : ""}>
                        
                        {this.props.module && this.props.module.texts ? this.props.module.texts.map((text)=>(
                            <option key={text.text} value={text.text}>{text.text}</option>
                        )) : ""}

                    </select>
                </label> 
                <label>Цвет:
                    <select class="colorSelect" onChange={this.onSelectedColor} name="ModuleColor" id="moduleColorSelect" style={{width:"140px",margin:1} } 
                        value={this.props.module && this.props.module.color ? JSON.stringify(this.props.module.color) : ""}>
                        <option key="white" class="white" value="[0.9,0.9,0.9,1]">Белый</option>
                        <option key="red" class="red" value="[1,0,0,1]">Красный</option>
                        <option key="green" class="darkgreen" value="[0,0.5,0,1]">Зеленный</option>
                        <option key="blue" class="lightblue" value="[0,0.5,1,1]">Синий</option>
                        <option key="yellow" class="yellow" value="[1,1,0,1]">Желтый</option>
                        <option key="black" class="black" value="[0.2,0.2,0.2,1]">Черный</option>
                    </select>
                </label>
            </fieldset>
            }
            </div>
        )
    }
})

export default ToolbarModuleView;