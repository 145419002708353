import React from 'react';

class BoxModuleView extends React.Component {

  render() {

    let width = this.props.module.sx-40;
    let height = this.props.module.sy-40;
    let outter_path = "M20,0 h" + width + " a20,20 0 0 1 20,20 v" + 
                                  height + " a20,20 0 0 1 -20,20 h-" + 
                                  width + " a20,20 0 0 1 -20,-20 v-" + 
                                  height + " a20,20 0 0 1 20,-20 z";

    let inner_width = this.props.module.sx-40;
    let inner_height = this.props.module.sy-40;
    let inner_path = "M20,10 h" + inner_width + " a10,10 0 0 1 10,10 v" + 
                                  inner_height + " a10,10 0 0 1 -10,10 h-" + 
                                  inner_width + " a10,10 0 0 1 -10,-10 v-" + 
                                  inner_height + " a10,10 0 0 1 10,-10 z";

    return (
        <g id={this.props.id} transform={"translate(" + this.props.x + "," + this.props.y +")"}>
          <path d={outter_path} stroke="black" stroke-width="0.5" fill="white"/>
          <path d={inner_path} stroke="black" stroke-width="0.5" fill="white"/>

          {this.props.module.cleats.filter((cleat) => (cleat.accept === "box")).map((cleat) => (
            <g id={this.props.id}>
                <rect x={parseInt(cleat.x) + 50} y={parseInt(cleat.y) + parseInt(this.props.module.sy)/2 - 17} 
                        width={this.props.module.sx-100} height="34" fill="none" stroke="black" stroke-width="0.5" stroke-dasharray="20,10"/>
            </g>
          ))}
          {this.props.module.cleats.filter((cleat) => (cleat.accept === "bus")).map((cleat) => (
            <g id={this.props.id}>
                <rect x={parseInt(cleat.x) + parseInt(this.props.module.sx)/2 + 1} y={parseInt(cleat.y) + parseInt(this.props.module.sy)/2 - 5} 
                        width="10" height="10" fill="none" stroke="black" stroke-width="0.5" stroke-dasharray="2,2"/>
            </g>
          ))}

        </g>
    );
  }
 
}

export default BoxModuleView;