import React from 'react'
import OpenFile from "../OpenFile.js"
import SaveFile from "../SaveFile.js"
import SavePng from "../SavePng.js"
import MenuMobile from './MenuMobile.js';
import ToolbarModuleView from '../views/ToolbarModuleView.js'
import ToolbarConnectorView from '../views/ToolbarConnectorView.js'
import { inject } from 'mobx-react';

var ToolbarViewMobile = inject("mainStore", "uiStore") (
    class ToolbarViewMobile extends React.Component {

    constructor(props) {
        super(props);

        this.mainStore = this.props.mainStore;
        this.uiStore = this.props.uiStore;

        this.onMenu = this.onMenu.bind(this);
        this.onConnectionModeChange = this.onConnectionModeChange.bind(this);

        this.state = {
            showPopup: false,
            connectionMode: this.uiStore.connectionMode
        }
    }

    onMenu() {
        this.setState({showPopup:true});
    }

    onConnectionModeChange(event) {
        this.uiStore.connectionMode=event.target.value;
        this.setState({connectionMode: this.uiStore.connectionMode});
    }

    render() {
        return (
            <div>
                <div style={{display:"flex"} }>
                    <OpenFile/>
                    <SaveFile/>
                    <SavePng/>
        
                        <input type="radio" id="rdbtnWire" name="radio-default" onChange={this.onConnectionModeChange} 
                            checked={this.state.connectionMode!="Busbar" && this.state.connectionMode!="EditConnector"} class="rdbtnConnectionMode" value="Wire"/>
                        <label class="wire" for="rdbtnWire">Wire</label>

                        <input type="radio" id="rdbtnBusbar" name="radio-default" onChange={this.onConnectionModeChange}
                            checked={this.state.connectionMode=="Busbar"} class="rdbtnConnectionMode" value="Busbar"/>
                        <label class="busbar" for="rdbtnBusbar">Busbar</label>

                        <input type="radio" id="rdbtnEditConnector" name="radio-default" onChange={this.onConnectionModeChange}
                            checked={this.state.connectionMode=="EditConnector"} class="rdbtnConnectionMode" value="EditConnector"/>
                        <label class="editconnector" for="rdbtnEditConnector">EditConnector</label>

                    <MenuMobile onChange={this.onMenu}/>
                </div>
                {this.mainStore.currentModuleSelected >= 0 && this.state.showPopup ?
                    <div className='popup'>
                        <div className='popup_inner'>
                            <ToolbarModuleView module = {this.mainStore.modules[this.mainStore.currentModuleSelected]}/>
                            <div style={{padding: "5px" }}>
                                <button style={{display: "block", margin:"auto", padding: "5px 20px" }} onClick={()=>{this.setState({showPopup:false})}}>Закрыть</button>
                            </div>
                        </div>
                    </div>
                        : ""}
                {this.mainStore.currentWireSelected.index >=0 && this.state.showPopup  ?
                    <div className='popup'>
                        <div className='popup_inner'>
                            <ToolbarConnectorView wire={this.mainStore.wires[this.mainStore.currentWireSelected.index]}/>
                            <div style={{padding: "5px" }}>
                                <button style={{display: "block", margin:"auto", padding: "5px 20px" }} onClick={()=>{this.setState({showPopup:false})}}>Закрыть</button>
                            </div>
                        </div>
                    </div>
                    :""
                }
            </div>        
        )
    }
})

export default ToolbarViewMobile;