import React from 'react';
import uuid from 'react-uuid';
import ModuleView from './ModuleView';
import WireView from './WireView';
import BusbarView from './BusbarView';
import ToolbarView from '../toolbar/ToolbarView';
import ToolbarViewMobile from '../toolbar/mobile/ToolbarViewMobile';
import BoxPopup from '../popups/BoxPopup';
import OpenPopup from '../popups/OpenPopup';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import uiStore from '../../stores/UiStore';


const isTouchDevice = () => 'ontouchstart' in window || window.DocumentTouch && document instanceof window.DocumentTouch || navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0;

var CanvasView = inject("mainStore", "uiStore") (observer(
  class CanvasView extends React.Component {

    constructor(props) {
        super(props);

        this.mainStore = this.props.mainStore;
        this.uiStore = this.props.uiStore;

        this.state = {
            refresh:true,
            isDown: false,
            isOnTrash: false,
            lastDeltaY: 0,
            lastX: 0,
            lastY: 0,
            currentInserted: -1,
            wireInserted: {id: null, pt1: {id:null, module:null, x:0, y:0, directionx:0, directiony:0}, 
                                     pt2: {id:null, module:null, x:0, y:0, directionx:0, directiony:0},
                                     color: [0,0,1,1],
                                     alignment: 0,
                                     type: "Wire",
                                     pole: 1}
        };
    }

    setSelectedRect (inputIndex) {
        if (inputIndex >= 0) {

            var selectedModule = this.mainStore.modules[inputIndex];
            var x = selectedModule.x; 
            var y = selectedModule.y; 
            var parentCleatIndex = -1;
            var parentModuleIndex = -1;
            var selectedCleatId = null;

            var minDelta = Number.MAX_VALUE;
            for (let i = 0; i < this.mainStore.modules.length; i++) {
                if (i !== inputIndex) {
                    var module = this.mainStore.modules[i];
                    module.cleats.forEach((cleat,j)=>{
                        var cleatX = module.x + module.sx/2 + Number(cleat.x);
                        var cleatY = module.y + module.sy/2 + Number(cleat.y);
                        selectedModule.cleats.forEach((selCleat)=> {
                            if (cleat.accept && selCleat.type && cleat.accept === selCleat.type && (cleat.connected == null || cleat.connected.cleatId === selCleat.id)) {
                                var selCleatX = selectedModule.x + selectedModule.sx/2 + Number(selCleat.x);
                                var selCleatY = selectedModule.y + selectedModule.sy/2 + Number(selCleat.y);
                                var deltaX = selCleatX - cleatX;
                                var deltaY = selCleatY - cleatY;
                                var delta = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                                if (delta < minDelta && Math.abs(deltaX) < 50 && Math.abs(deltaY) < 50) {
                                    minDelta = delta;
                                    x = selectedModule.x - deltaX;
                                    y = selectedModule.y - deltaY;
                                    parentModuleIndex = i;
                                    parentCleatIndex = j;
                                    selectedCleatId = selCleat.id;
                                }
                            }
                        });
                    });
                }
            }
        }

        this.setState({ boundRectX: x,
                        boundRectY: y,
                        parentModuleIndex: parentModuleIndex,
                        parentCleatIndex: parentCleatIndex,
                        selectedCleatId: selectedCleatId });
    }

    onDrop(event) {
        this.mainStore.modules[this.state.currentInserted].x = this.state.boundRectX;
        this.mainStore.modules[this.state.currentInserted].y = this.state.boundRectY;
        if (this.state.parentModuleIndex >= 0) {
            this.mainStore.modules[this.state.parentModuleIndex].cleats[this.state.parentCleatIndex].connected = {cleatId:this.state.selectedCleatId};
            this.state.parentModuleIndex = -1;
        }

        if(this.state.isOnTrash) {
            this.state.isOnTrash = false;
            this.mainStore.modules.splice(this.state.currentInserted, 1);
            this.state.currentInserted = -1;
        } else if (this.mainStore.modules[this.state.currentInserted].catalog==="BOX") {
            this.uiStore.boxPopup.index = this.state.currentInserted;
            this.uiStore.boxPopup.rowcount = this.mainStore.modules[this.state.currentInserted].rowcount;
            this.uiStore.boxPopup.dincount = this.mainStore.modules[this.state.currentInserted].dincount;
            this.uiStore.boxPopup.show = !this.uiStore.boxPopup.show;
        } else if (this.mainStore.modules[this.state.currentInserted].catalog==="BUS") {
            this.toggleBusPopup(this.state.currentInserted, this.mainStore.modules[this.state.currentInserted].dincount);
        }

        this.mainStore.updateMinMax();

        this.setState({
            currentModuleSelected: this.state.currentInserted,
            currentInserted: -1
        })

        event.stopPropagation();
        event.preventDefault();
    }

    onDragEnter(event) {
        this.mainStore.currentModuleSelected = -1;

        if(this.state.currentInserted < 0) {
            var svg = document.querySelector('svg');
            let module = JSON.parse(svg.getAttributeNS(null, "data-x"));
            if (module) {
                module.x = event.clientX/this.uiStore.scale - module.sx * 0.5 - this.uiStore.offsetX/this.uiStore.scale;
                module.y = event.clientY/this.uiStore.scale - module.sy * 0.5 - this.uiStore.offsetY/this.uiStore.scale;
                if (!module.z) module.z = 0;
                
                module.id = uuid();
                module.cleats.forEach((cleat)=>(cleat.id=uuid()));
                module.color = this.uiStore.lastModuleSelectedColor;
    
                this.mainStore.modules = this.mainStore.modules.concat(module);
                this.mainStore.modules.sort((a,b) => Number(a.z) - Number(b.z));
                this.state.currentInserted = this.mainStore.modules.findIndex((m)=>m.id===module.id);

                svg.setAttributeNS(null,"data-x",null);

                this.setSelectedRect(this.state.currentInserted);
            }
        }

        event.stopPropagation();
        event.preventDefault();
    }

    onDragOver(event) {
        if (this.state.currentInserted >= 0) {
            let module = this.mainStore.modules[this.state.currentInserted];
            module.x = event.clientX/this.uiStore.scale - module.sx * 0.5 - this.uiStore.offsetX/this.uiStore.scale;
            module.y = event.clientY/this.uiStore.scale - module.sy * 0.5 - this.uiStore.offsetY/this.uiStore.scale;

            this.state.isOnTrash = (event.clientX > 0 && event.clientX < 50 && event.clientY > 0 && event.clientY < 50);

            this.setSelectedRect(this.state.currentInserted);
        }

        event.stopPropagation();
        event.preventDefault();
    }

    length(x1,y1,x2,y2) {
        return Math.sqrt((x1-x2)*(x1-x2) + (y1-y2)*(y1-y2));
    }

    updateWires(module) {
        this.mainStore.wires.forEach((wire)=>{
            if (wire.pt1.module.id === module.id) {
                let cleat = module.cleats.find((cleat)=>cleat.id===wire.pt1.id);
                let oldX = wire.pt1.x;
                let oldY = wire.pt1.y;
                wire.pt1.x = Number(cleat.x) + Number(module.x) + Number(module.sx)/2;
                wire.pt1.y = Number(cleat.y) + Number(module.y) + Number(module.sy)/2;
                wire.pt1.module = module;
                let deltaX = wire.pt1.x - oldX;
                let deltaY = wire.pt1.y - oldY;
                if (Number(wire.alignment) === 3) {
                    let oldControlPoints = Array(12);
                    let controlPointIsChanged = Array(12);
                    for (let i=0;i<12;i++) {
                        oldControlPoints[i] = wire.controlPoints[i];
                        controlPointIsChanged[i] = false;
                    }
                    wire.controlPoints[0] +=  deltaX;
                    wire.controlPoints[1] +=  deltaY;
                    wire.controlPoints[2] +=  deltaX;
                    wire.controlPoints[3] +=  deltaY;

                    if (Math.abs(oldControlPoints[2]-oldControlPoints[4]) < 0.001) {
                        wire.controlPoints[4] +=  deltaX;
                        controlPointIsChanged[4] = true;
                    }
                    if (Math.abs(oldControlPoints[3]-oldControlPoints[5]) < 0.001) {
                        wire.controlPoints[5] +=  deltaY;
                        controlPointIsChanged[5] = true;
                    }
                    if (Math.abs(oldControlPoints[4]-oldControlPoints[6]) < 0.001 && controlPointIsChanged[4]) {
                        wire.controlPoints[6] +=  deltaX;
                    }
                    if (Math.abs(oldControlPoints[5]-oldControlPoints[7]) < 0.001 && controlPointIsChanged[5]) {
                        wire.controlPoints[7] +=  deltaY;
                        controlPointIsChanged[7] = true;
                    }
                    if (Math.abs(oldControlPoints[7]-oldControlPoints[9]) < 0.001 && controlPointIsChanged[7]) {
                        wire.controlPoints[9] +=  deltaY;
                    }
                }
            } 
            if (wire.pt2.module.id === module.id) {
                let cleat = module.cleats.find((cleat)=>cleat.id===wire.pt2.id);
                let oldX = wire.pt2.x;
                let oldY = wire.pt2.y;
                wire.pt2.x = Number(cleat.x) + Number(module.x) + Number(module.sx)/2;
                wire.pt2.y = Number(cleat.y) + Number(module.y) + Number(module.sy)/2;
                wire.pt2.module = module;
                let deltaX = wire.pt2.x - oldX;
                let deltaY = wire.pt2.y - oldY;
                if (Number(wire.alignment) === 3) {
                    let oldControlPoints = Array(12);
                    let controlPointIsChanged = Array(12);
                    for (let i=0;i<12;i++) {
                        oldControlPoints[i] = wire.controlPoints[i];
                        controlPointIsChanged[i] = false;
                    }
                    wire.controlPoints[10] +=  deltaX;
                    wire.controlPoints[11] +=  deltaY;
                    wire.controlPoints[8] +=  deltaX;
                    wire.controlPoints[9] +=  deltaY;

                    if (Math.abs(oldControlPoints[8]-oldControlPoints[6]) < 0.001) {
                        wire.controlPoints[6] +=  deltaX;
                        controlPointIsChanged[6] = true;
                    }
                    if (Math.abs(oldControlPoints[9]-oldControlPoints[7]) < 0.001) {
                        wire.controlPoints[7] +=  deltaY;
                        controlPointIsChanged[7] = true;
                    }
                    if (Math.abs(oldControlPoints[4]-oldControlPoints[6]) < 0.001 && controlPointIsChanged[6]) {
                        wire.controlPoints[4] +=  deltaX;
                    }
                    if (Math.abs(oldControlPoints[5]-oldControlPoints[7]) < 0.001 && controlPointIsChanged[7]) {
                        wire.controlPoints[5] +=  deltaY;
                        controlPointIsChanged[5] = true;
                    }
                    if (Math.abs(oldControlPoints[3]-oldControlPoints[5]) < 0.001 && controlPointIsChanged[5]) {
                        wire.controlPoints[3] +=  deltaY;
                    }
                }
            }
        });
    }

    updateModules(module) {
        module.cleats.forEach((cleat)=>{
            if (cleat.connected && cleat.connected.cleatId) {
                var updatedModuleIndex = this.mainStore.modules.findIndex((module)=>module.cleats.find((cleatTmp)=>cleatTmp.id===cleat.connected.cleatId)); //getModuleIndexByCleatId
                var updatedModuleCleat = this.mainStore.modules[updatedModuleIndex].cleats.find((cl)=>cl.id===cleat.connected.cleatId);

                this.mainStore.modules[updatedModuleIndex].x = Number(cleat.x) + Number(module.x) + Number(module.sx)/2 - 
                        Number(updatedModuleCleat.x) - Number(this.mainStore.modules[updatedModuleIndex].sx)/2;
                this.mainStore.modules[updatedModuleIndex].y = Number(cleat.y) + Number(module.y) + Number(module.sy)/2 - 
                        Number(updatedModuleCleat.y) - Number(this.mainStore.modules[updatedModuleIndex].sy)/2;

                this.updateWires(this.mainStore.modules[updatedModuleIndex]);
                this.updateModules(this.mainStore.modules[updatedModuleIndex]);
            }
        });
    }

    deleteModule(index) {
        for( var i = 0; i < this.mainStore.wires.length; i++){ 
            if ( this.mainStore.wires[i].pt1.module.id === this.mainStore.modules[index].id ||
                 this.mainStore.wires[i].pt2.module.id === this.mainStore.modules[index].id) { 
                    this.mainStore.wires.splice(i, 1); 
                    i--; 
            }
        }

        var module = this.mainStore.modules[index];
        this.mainStore.modules.splice(index, 1);

        module.cleats.forEach((cleat)=>{
            if (cleat.connected && cleat.connected.cleatId) {
                var deletedModuleIndex = this.mainStore.modules.findIndex((module)=>module.cleats.find((cleatTmp)=>cleatTmp.id===cleat.connected.cleatId)); //getModuleIndexByCleatId
                this.deleteModule(deletedModuleIndex);
            }
            this.mainStore.modules.forEach((mod)=>mod.cleats.forEach((cl)=>{
                if (cl.connected && cl.connected.cleatId===cleat.id) {
                    cl.connected.cleatId = null;
                    cl.connected = null;
                }
            }));
        });
        this.mainStore.updateMinMax();
    }

    onMouseMove(event, touch){
        let eventClientX = event.clientX;
        let eventClientY = event.clientY;
        let eventMovementX = event.movementX;
        let eventMovementY = event.movementY;

        if (touch) {
            eventClientX = touch.pageX;
            eventClientY = touch.pageY;
            eventMovementX=Number(eventClientX-this.state.lastX);
            eventMovementY=Number(eventClientY-this.state.lastY);
        }  else if (isTouchDevice())return;

        if(this.state.isDown) {
            if (!!!touch) {
                event.preventDefault();
            }
            event.stopPropagation();
          

            if (this.mainStore.currentModuleSelected >= 0) {   //module is selected
                module = this.mainStore.modules[this.mainStore.currentModuleSelected];

                module.x = module.x + eventMovementX/this.uiStore.scale;
                module.y = module.y + eventMovementY/this.uiStore.scale;
                
                this.state.isOnTrash = (eventClientX > 0 && eventClientX < 50 && eventClientY > 0 && eventClientY < 50);

                this.setSelectedRect(this.mainStore.currentModuleSelected);

                this.mainStore.updateMinMax();

                this.updateWires(module);
                this.updateModules(module);

                this.setState({lastX:Number(eventClientX), lastY:Number(eventClientY)});
            } else if (this.state.wireInserted.pt1.id) {   // wire pt is selected

                var directiony = this.state.wireInserted.pt2.directiony;
                var directionx = this.state.wireInserted.pt2.directionx;
                var first_element = document.elementFromPoint(eventClientX, eventClientY);
                if (first_element.nodeName === "circle") {
                    directiony = first_element.getAttributeNS(null,"directiony");
                    directionx = first_element.getAttributeNS(null,"directionx");
                }
                this.setState({wireInserted:{   id:     this.state.wireInserted.id,
                                                color:  this.state.wireInserted.color,
                                                alignment:  this.state.wireInserted.alignment,
                                                type:   this.state.wireInserted.type,
                                                pole:   this.state.wireInserted.pole,
                                                pt1:    this.state.wireInserted.pt1,
                                                pt2:    {x:eventClientX/this.uiStore.scale - this.uiStore.offsetX/this.uiStore.scale, 
                                                         y:eventClientY/this.uiStore.scale - this.uiStore.offsetY/this.uiStore.scale,
                                                         directiony:directiony,
                                                         directionx:directionx
                                                        }}});

            } else if (this.mainStore.currentWireSelected.index >= 0) {
                this.mainStore.wires[this.mainStore.currentWireSelected.index].alignment = 3;
                if (this.mainStore.wires[this.mainStore.currentWireSelected.index].controlPoints) {
                    if (this.mainStore.currentWireSelected.isX) {
                        this.mainStore.wires[this.mainStore.currentWireSelected.index].controlPoints[this.mainStore.currentWireSelected.point1] = eventClientX/this.uiStore.scale - this.uiStore.offsetX/this.uiStore.scale;
                        this.mainStore.wires[this.mainStore.currentWireSelected.index].controlPoints[this.mainStore.currentWireSelected.point2] = eventClientX/this.uiStore.scale - this.uiStore.offsetX/this.uiStore.scale;
                    } else {
                        this.mainStore.wires[this.mainStore.currentWireSelected.index].controlPoints[this.mainStore.currentWireSelected.point1] = eventClientY/this.uiStore.scale - this.uiStore.offsetY/this.uiStore.scale;
                        this.mainStore.wires[this.mainStore.currentWireSelected.index].controlPoints[this.mainStore.currentWireSelected.point2] = eventClientY/this.uiStore.scale - this.uiStore.offsetY/this.uiStore.scale;
                    }
                    this.setState({refresh: !this.state.refresh});
                }
            } else {
                this.uiStore.offsetX += eventMovementX;
                this.uiStore.offsetY += eventMovementY;
                this.setState({
                    lastX:Number(eventClientX), 
                    lastY:Number(eventClientY)
                });
            }
        }
    }

    onMouseDown(event, touch){
        let eventClientX = event.clientX;
        let eventClientY = event.clientY;
        
        if (touch) {
            eventClientX = touch.pageX;
            eventClientY = touch.pageY;
        }  else if (isTouchDevice())return;

        if (!!!touch) {
            event.preventDefault();
        }
        event.stopPropagation();

        this.state.lastX=Number(eventClientX);
        this.state.lastY=Number(eventClientY);
      
        var first_element = document.elementFromPoint(eventClientX, eventClientY);
        if (first_element.nodeName === "svg") {
            this.mainStore.currentModuleSelected = -1;
            this.mainStore.currentWireSelected.index = -1;
            var svg = document.querySelector('svg');
            svg.style.cursor = "grab";
        } else if (first_element.nodeName === "image" || first_element.nodeName === "path" || first_element.nodeName === "rect") {
            let id = first_element.parentNode.getAttributeNS(null,"id");
            this.mainStore.currentWireSelected.index = -1;
            this.mainStore.currentModuleSelected = this.mainStore.modules.findIndex(module=>module.id===id);

            this.setSelectedRect(this.mainStore.currentModuleSelected);

            var svg = document.querySelector('svg');
            svg.style.cursor = "move";
        } else if (first_element.nodeName === "circle") {
            console.log(first_element.getAttributeNS(null,"id"));
            console.log(event.button);
            let moduleId = first_element.parentNode.getAttributeNS(null,"id");
            let module = this.mainStore.modules.find(module=>module.id===moduleId);
            if ((touch && this.uiStore.connectionMode != "EditConnector") || (event.button === 0 && !event.ctrlKey)) {
                this.state.wireInserted.pt1.id = first_element.getAttributeNS(null,"id");
                this.state.wireInserted.id = uuid();
                this.state.wireInserted.pt1.module = module;
                this.state.wireInserted.pt1.x = Number(first_element.getAttributeNS(null,"cx")) + Number(module.x);
                this.state.wireInserted.pt1.y = Number(first_element.getAttributeNS(null,"cy")) + Number(module.y);
                this.state.wireInserted.pt1.directionx = first_element.getAttributeNS(null,"directionx");
                this.state.wireInserted.pt1.directiony = first_element.getAttributeNS(null,"directiony");
                this.state.wireInserted.pt2.x = eventClientX/this.uiStore.scale - this.uiStore.offsetX/this.uiStore.scale;
                this.state.wireInserted.pt2.y = eventClientY/this.uiStore.scale - this.uiStore.offsetY/this.uiStore.scale;
                this.state.wireInserted.alignment = 0;
                this.state.wireInserted.pole = 1;
                this.state.wireInserted.color = this.uiStore.lastWireSelectedColor;
                this.state.wireInserted.type = this.uiStore.connectionMode;
            } else {
                console.log("this.uiStore.connectionMode = " +this.uiStore.connectionMode);
                let cleatId = first_element.getAttributeNS(null,"id");
                let editWireIndex = this.mainStore.wires.findIndex((wire) => wire.pt1.id===cleatId || wire.pt2.id===cleatId);
                if (editWireIndex >= 0) {
                    this.state.wireInserted = JSON.parse(JSON.stringify(this.mainStore.wires[editWireIndex]));
                    this.state.wireInserted.alignment = 0;
                    this.mainStore.wires.splice(editWireIndex, 1);
                    if (this.state.wireInserted.pt1.id===cleatId) {
                        let tmpPt = this.state.wireInserted.pt1;
                        this.state.wireInserted.pt1 = this.state.wireInserted.pt2;
                        this.state.wireInserted.pt2 = tmpPt;
                    }
                } else {
                    this.state.wireInserted.pt1.id = null;
                    console.log("return");
                    return;
                }
            }

            this.mainStore.currentModuleSelected = -1;
            this.mainStore.currentWireSelected.index = -1;
        } else if (first_element.nodeName === "line") {
            let id = first_element.parentNode.getAttributeNS(null,"id");
            this.mainStore.currentModuleSelected = -1;
            this.mainStore.currentWireSelected.index = this.mainStore.wires.findIndex(wire=>wire.id===id);
            this.mainStore.currentWireSelected.point1 = parseInt(first_element.getAttributeNS(null,"point1"));
            this.mainStore.currentWireSelected.point2 = parseInt(first_element.getAttributeNS(null,"point2"));
            this.mainStore.currentWireSelected.isX = first_element.getAttributeNS(null,"isX") === "true";
        }

        this.setState({isDown:true});
    }
      
    onMouseUp(event, touch){
        let eventClientX = event.clientX;
        let eventClientY = event.clientY;
        
        if (touch) {
            eventClientX = touch.clientX;
            eventClientY = touch.clientY;
        }  else if (isTouchDevice())return;

        if (this.state.isDown) {
            event.preventDefault();
            event.stopPropagation();
          
            this.state.lastX=0;
            this.state.lastY=0;
    
            var svg = document.querySelector('svg');
            svg.style.cursor = "auto";

            if (this.mainStore.currentModuleSelected >= 0) {
                if (this.state.isOnTrash) {
                    this.deleteModule(this.mainStore.currentModuleSelected);
                    this.mainStore.currentModuleSelected = -1;
                    this.setState({isOnTrash:false});
                } else {
                    this.mainStore.modules[this.mainStore.currentModuleSelected].x = this.state.boundRectX;
                    this.mainStore.modules[this.mainStore.currentModuleSelected].y = this.state.boundRectY;
                    this.mainStore.modules[this.mainStore.currentModuleSelected].cleats.forEach((cleat)=>{
                        this.mainStore.modules.forEach((mod)=>mod.cleats.forEach((cl)=>{
                            if (cl.connected && cl.connected.cleatId===cleat.id) {
                                cl.connected.cleatId = null;
                                cl.connected = null;
                            }
                        }));
                    });
                    if (this.state.parentModuleIndex >= 0) {
                        this.mainStore.modules[this.state.parentModuleIndex].cleats[this.state.parentCleatIndex].connected = {cleatId:this.state.selectedCleatId};
                        this.state.parentModuleIndex = -1;
                    }

                    this.updateWires(this.mainStore.modules[this.mainStore.currentModuleSelected]);
                    this.updateModules(this.mainStore.modules[this.mainStore.currentModuleSelected]);
                    this.mainStore.updateMinMax();
                }
            } else {
                var first_element = document.elementFromPoint(eventClientX, eventClientY);
                if (first_element.nodeName === "circle") {
                    let moduleId = first_element.parentNode.getAttributeNS(null,"id");
                    let module = this.mainStore.modules.find(module=>module.id===moduleId);
        
                    this.state.wireInserted.pt2.id = first_element.getAttributeNS(null,"id");
                    this.state.wireInserted.pt2.module = module;
                    this.state.wireInserted.pt2.x = Number(first_element.getAttributeNS(null,"cx")) + Number(module.x);
                    this.state.wireInserted.pt2.y = Number(first_element.getAttributeNS(null,"cy")) + Number(module.y);
                    this.state.wireInserted.pt2.directionx = first_element.getAttributeNS(null,"directionx");
                    this.state.wireInserted.pt2.directiony = first_element.getAttributeNS(null,"directiony");

                    const wire = JSON.parse(JSON.stringify(this.state.wireInserted));
                    wire.color = this.state.wireInserted.color;
                    wire.alignment = this.state.wireInserted.alignment;
                    this.mainStore.currentWireSelected.index = this.mainStore.wires.push(wire) - 1;

                    this.state.wireInserted.pt1.id = null;

                    this.mainStore.currentModuleSelected = -1;
                } else {
                    this.state.wireInserted.pt1.id = null;
                }
            }

            this.setState({isDown:false})
        }
    }

    onDoubleClick(event) {
        event.preventDefault();
        event.stopPropagation();

        this.mainStore.updateMinMax();

        this.uiStore.offsetX = -this.mainStore.min.x * this.uiStore.scale + 100;
        this.uiStore.offsetY = -this.mainStore.min.y * this.uiStore.scale + 100;
        this.setState({refresh: !this.state.refresh});
    }

    onZoom(newScale, eventClientX, eventClientY) {

        if (newScale < 0.3) {
            newScale = 0.3;
        } else if (newScale > 4.0) {
            newScale = 4.0;
        }

        console.log("onZoom newScale = " + newScale);
        console.log("onZoom eventClientX = " + eventClientX);
        console.log("onZoom eventClientY = " + eventClientY);

        var oldScale = this.uiStore.scale;
        this.uiStore.scale = newScale;

        var oldX = Number(eventClientX-this.uiStore.offsetX)/oldScale * this.uiStore.scale + this.uiStore.offsetX;
        var oldY = Number(eventClientY-this.uiStore.offsetY)/oldScale * this.uiStore.scale + this.uiStore.offsetY;

        this.uiStore.offsetX = this.uiStore.offsetX + (Number(eventClientX) - oldX);
        this.uiStore.offsetY = this.uiStore.offsetY + (Number(eventClientY) - oldY);
        this.setState({refresh: !this.state.refresh});
    }

    onMouseWheel(event) {
        var newScale = this.uiStore.scale + Math.sign(event.deltaY) * 0.1;
        
        if (newScale > 1.0) {
            newScale += Math.sign(event.deltaY) * 0.1
        }

        this.onZoom(newScale, event.clientX, event.clientY);
    }

    onKeyDown(event) {
        if (event.key === 'Delete' && this.mainStore.currentModuleSelected >= 0) {
            this.deleteModule(this.mainStore.currentModuleSelected);
            this.mainStore.currentModuleSelected = -1;
            this.setState({isOnTrash:false});
        }
    }

    componentDidMount() {
        window.onkeydown = (event)=>this.onKeyDown(event);
        document.addEventListener( "contextmenu", function(e) { e.preventDefault(); });
        reaction(
            () => (uiStore.refreshFlag),
            () => {
                this.setState({refresh: !this.state.refresh});
            }
        )
    }

    propagatePointsUp(points, index) {
        this.mainStore.wires[index].controlPoints = points;
    }

    toggleBusPopup(index, dincount) {
        var module = this.mainStore.modules[index];
        if (module.sx > module.sy) {
            module.dincount = dincount;
            module.sx = dincount * 12;
    
            var x = - module.sx / 2 + 6;
            for(var i=0; i < dincount; i++) {
                var cleat = {id: uuid(), accept: "in", x:x, y: 0}
                module.cleats = module.cleats.concat(cleat);
                x += 12;
            }
        } else {
            var rowcount = module.sy / 12;
    
            var y = - module.sy / 2 + 6;
            for(var i=0; i < rowcount; i++) {
                var cleat = {id: uuid(), accept: "in", x: 0, y: y, direction:Array(1,0)}
                module.cleats = module.cleats.concat(cleat);
                y += 12;
            }
        }

    }

    onTouchStart(event) {
        console.log("onTouchStart event.touches.length=" + event.touches.length);
        if (event.touches.length > 1) {
            var zw = event.touches[0].pageX - event.touches[1].pageX, zh = event.touches[0].pageY - event.touches[1].pageY;
            this.state.lastDeltaY = Math.sqrt(zw * zw + zh * zh);
            console.log("onTouchStart this.state.lastDeltaY=" + this.state.lastDeltaY);
        } else {
            this.onMouseDown(event, event.touches[0]);
        }
    }

    onTouchMove(event) {
        console.log("onTouchMove event.touches.length=" + event.touches.length);
        if (event.touches.length > 1) {

            var zw = event.touches[0].pageX - event.touches[1].pageX, zh = event.touches[0].pageY - event.touches[1].pageY;

            let newDeltaY = Math.sqrt(zw * zw + zh * zh);
            console.log("onTouchMove newDeltaY=" + newDeltaY);
            console.log("onTouchMove this.state.lastDeltaY=" + this.state.lastDeltaY);

            this.onZoom(this.uiStore.scale + Math.sign(newDeltaY - this.state.lastDeltaY) * 0.01, (event.touches[0].pageX + event.touches[1].pageX) / 2, 
                                          (event.touches[0].pageY + event.touches[1].pageY) / 2);
            this.state.lastDeltaY = newDeltaY;
        } else {
            this.onMouseMove(event, event.changedTouches[0]);
        }
    }

    onTouchEnd(event) {
        this.onMouseUp(event, event.changedTouches[0]);
    }

    renderDesktop() {
            return (
            <main>
                <svg draggable="true" onDragEnter={(e)=>this.onDragEnter(e)} 
                      onDrop={(e)=>{this.onDrop(e)}} 
                      onDragOver={(e)=>{this.onDragOver(e)}} 
                      onMouseMove={(e)=>{this.onMouseMove(e)}} 
                      onMouseDown={(e)=>{this.onMouseDown(e)}} 
                      onMouseUp={(e)=>{this.onMouseUp(e)}}
                      onTouchStart={(e)=>{this.onTouchStart(e)}} 
                      onTouchEnd={(e)=>{this.onTouchEnd(e)}} 
                      onTouchMove={(e)=>{this.onTouchMove(e)}} 
                      onMouseWheel={(e)=>{this.onMouseWheel(e)}}
                      onDoubleClick={(e)=>{this.onDoubleClick(e)}}
                      onWheel={(e)=>{this.onMouseWheel(e)}}
                >
                    <g transform={"matrix(" + this.uiStore.scale + ", 0, 0, " + this.uiStore.scale + ", " + this.uiStore.offsetX + ", " + this.uiStore.offsetY + ")"}>
                      <g>
                        {this.mainStore.modules.filter((module) => module.z<0).map((module) => (
                            <ModuleView key={module.id} x={module.x} y={module.y} id={module.id} module={module}/>
                        ))}
                        {this.state.wireInserted.pt1.id ? this.state.wireInserted.type !=="Busbar" ? 
                            <WireView id={this.state.wireInserted.id} 
                                    pt1={this.state.wireInserted.pt1} pt2={this.state.wireInserted.pt2} color={[0.5,0.5,0.5,1.0]} 
                                    min={this.mainStore.min} max={this.mainStore.max} alignment={this.state.wireInserted.alignment}/> :
                            <BusbarView id={this.state.wireInserted.id} 
                                    pt1={this.state.wireInserted.pt1} pt2={this.state.wireInserted.pt2} color={[0.5,0.5,0.5,1.0]} pole={this.state.wireInserted.pole} /> : ""}
                        
                        {this.mainStore.wires.map((wire, index) => (
                            wire.type!=="Busbar" ?
                            <WireView key={wire.id} id={wire.id} index={index} pt1={wire.pt1} pt2={wire.pt2} color={wire.color}
                                selected={index===this.mainStore.currentWireSelected.index} min={this.mainStore.min} max={this.mainStore.max} alignment={wire.alignment}
                                controlPoints={wire.controlPoints} propagatePointsUp={(points, id)=>this.propagatePointsUp(points, id)}/> : 
                            <BusbarView key={wire.id} id={wire.id} index={index} pt1={wire.pt1} pt2={wire.pt2} color={wire.color} pole={wire.pole}/>
                        ))}
                        {
                            this.mainStore.currentModuleSelected >= 0 ? <rect x={this.state.boundRectX} y={this.state.boundRectY} 
                                            width={this.mainStore.modules[this.mainStore.currentModuleSelected].sx} height={this.mainStore.modules[this.mainStore.currentModuleSelected].sy}
                                            style={{stroke:'red',strokeWidth:'3',fillOpacity:'0'}}/> : 
                            this.state.currentInserted >= 0 ? <rect x={this.state.boundRectX} y={this.state.boundRectY} 
                                            width={this.mainStore.modules[this.state.currentInserted].sx} height={this.mainStore.modules[this.state.currentInserted].sy}
                                            style={{stroke:'red',strokeWidth:'3',fillOpacity:'0'}}/> : ""
                        }
                        {this.mainStore.modules.filter((module) => module.z>=0).map((module) => (
                            <ModuleView key={module.id} x={module.x} y={module.y} id={module.id} module={module}/>
                        ))}
                      </g>
                    </g>
                    <image width="50" height="50" href={this.state.isOnTrash ? "trash_white.png" : "trash_black.png"}/>                    
                </svg>
                <ToolbarView currentModuleSelected = {this.mainStore.currentModuleSelected}
                             currentWireSelected={this.mainStore.currentWireSelected.index}/>
                
                {this.uiStore.boxPopup.show ? 
                     <BoxPopup/> : ""}
                {this.uiStore.openPopup.show ? 
                     <OpenPopup/> : ""}

            </main>
        )
    }

    renderMobile() {
            return (
            <main class="vertical_main">
                <svg draggable="true" onDragEnter={(e)=>this.onDragEnter(e)} 
                      onDrop={(e)=>{this.onDrop(e)}} 
                      onDragOver={(e)=>{this.onDragOver(e)}} 
                      onMouseMove={(e)=>{this.onMouseMove(e)}} 
                      onMouseDown={(e)=>{this.onMouseDown(e)}} 
                      onMouseUp={(e)=>{this.onMouseUp(e)}}
                      onTouchStart={(e)=>{this.onTouchStart(e)}} 
                      onTouchEnd={(e)=>{this.onTouchEnd(e)}} 
                      onTouchMove={(e)=>{this.onTouchMove(e)}} 
                      onMouseWheel={(e)=>{this.onMouseWheel(e)}}
                      onDoubleClick={(e)=>{this.onDoubleClick(e)}}
                      onWheel={(e)=>{this.onMouseWheel(e)}}
                >
                    <g transform={"matrix(" + this.uiStore.scale + ", 0, 0, " + this.uiStore.scale + ", " + this.uiStore.offsetX + ", " + this.uiStore.offsetY + ")"}>
                      <g>
                        {this.mainStore.modules.filter((module) => module.z<0).map((module) => (
                            <ModuleView key={module.id} x={module.x} y={module.y} id={module.id} module={module}/>
                        ))}
                        {this.state.wireInserted.pt1.id ? this.state.wireInserted.type !=="Busbar" ? 
                            <WireView id={this.state.wireInserted.id} 
                                    pt1={this.state.wireInserted.pt1} pt2={this.state.wireInserted.pt2} color={[0.5,0.5,0.5,1.0]} 
                                    min={this.mainStore.min} max={this.mainStore.max} alignment={this.state.wireInserted.alignment}/> :
                            <BusbarView id={this.state.wireInserted.id} 
                                    pt1={this.state.wireInserted.pt1} pt2={this.state.wireInserted.pt2} color={[0.5,0.5,0.5,1.0]} pole={this.state.wireInserted.pole} /> : ""}
                        
                        {this.mainStore.wires.map((wire, index) => (
                            wire.type!=="Busbar" ?
                            <WireView key={wire.id} id={wire.id} index={index} pt1={wire.pt1} pt2={wire.pt2} color={wire.color}
                                selected={index===this.mainStore.currentWireSelected.index} min={this.mainStore.min} max={this.mainStore.max} alignment={wire.alignment}
                                controlPoints={wire.controlPoints} propagatePointsUp={(points, id)=>this.propagatePointsUp(points, id)}/> : 
                            <BusbarView key={wire.id} id={wire.id} index={index} pt1={wire.pt1} pt2={wire.pt2} color={wire.color} pole={wire.pole}/>
                        ))}
                        {
                            this.mainStore.currentModuleSelected >= 0 ? <rect x={this.state.boundRectX} y={this.state.boundRectY} 
                                            width={this.mainStore.modules[this.mainStore.currentModuleSelected].sx} height={this.mainStore.modules[this.mainStore.currentModuleSelected].sy}
                                            style={{stroke:'red',strokeWidth:'3',fillOpacity:'0'}}/> : 
                            this.state.currentInserted >= 0 ? <rect x={this.state.boundRectX} y={this.state.boundRectY} 
                                            width={this.mainStore.modules[this.state.currentInserted].sx} height={this.mainStore.modules[this.state.currentInserted].sy}
                                            style={{stroke:'red',strokeWidth:'3',fillOpacity:'0'}}/> : ""
                        }
                        {this.mainStore.modules.filter((module) => module.z>=0).map((module) => (
                            <ModuleView key={module.id} x={module.x} y={module.y} id={module.id} module={module}/>
                        ))}
                      </g>
                    </g>
                    <image width="50" height="50" href={this.state.isOnTrash ? "trash_white.png" : "trash_black.png"}/>                    
                </svg>
                <ToolbarViewMobile />
                
                {this.uiStore.boxPopup.show ? 
                     <BoxPopup/> : ""}
                {this.uiStore.openPopup.show ? 
                     <OpenPopup/> : ""}

            </main>
        )
    }

    render() {
        if(window.matchMedia("(max-width: 767px)").matches){
            return this.renderMobile();
        } else{
            return this.renderDesktop();
        }
    }

}))

export default CanvasView;