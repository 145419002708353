import React from 'react';

class InModuleView extends React.Component {

  render() {

    const height = 20;

    return (
        <g id={this.props.id} transform={"translate(" + this.props.x + "," + this.props.y +")"}>
          {this.props.module.cleats.map((cleat, idx) => (
            <g id={this.props.id}>
                <rect x={idx * 18} y="0" 
                        width="18" height={height} fill={"rgb(" + (cleat.color?cleat.color[0]:0) * 255 + "," +  
                                                                  (cleat.color?cleat.color[1]:0) * 255 + "," + 
                                                                  (cleat.color?cleat.color[2]:0) * 255 + ")"} stroke="black" stroke-width="0.1"/>

                <g id={this.props.id}>
                        <circle key={idx} id={idx} cx={idx * 18 + 9} cy={height/2} r="4" fill="white"/>
                        <line x1={parseInt(cleat.x) + parseInt(this.props.module.sx)/2 - 4} y1={parseInt(cleat.y) + parseInt(this.props.module.sy)/2} 
                                x2={parseInt(cleat.x) + parseInt(this.props.module.sx)/2 + 4} y2={parseInt(cleat.y) + parseInt(this.props.module.sy)/2} 
                                strokeWidth="1.4" stroke="rgb(20,20,20)"/>
                        <line x1={parseInt(cleat.x) + parseInt(this.props.module.sx)/2} y1={parseInt(cleat.y) + parseInt(this.props.module.sy)/2 - 4} 
                                x2={parseInt(cleat.x) + parseInt(this.props.module.sx)/2} y2={parseInt(cleat.y) + parseInt(this.props.module.sy)/2 + 4}
                                strokeWidth="1.4" stroke="rgb(20,20,20)"/>
                        <line x1={parseInt(cleat.x) + parseInt(this.props.module.sx)/2 - 4} y1={parseInt(cleat.y) + parseInt(this.props.module.sy)/2} 
                                x2={parseInt(cleat.x) + parseInt(this.props.module.sx)/2 + 4} y2={parseInt(cleat.y) + parseInt(this.props.module.sy)/2} 
                                strokeWidth="1" stroke="rgb(170,170,170)"/>
                        <line x1={parseInt(cleat.x) + parseInt(this.props.module.sx)/2} y1={parseInt(cleat.y) + parseInt(this.props.module.sy)/2 - 4} 
                                x2={parseInt(cleat.x) + parseInt(this.props.module.sx)/2} y2={parseInt(cleat.y) + parseInt(this.props.module.sy)/2 + 4}
                                strokeWidth="1" stroke="rgb(170,170,170)"/>
                        <circle key={cleat.id} id={cleat.id} cx={parseInt(cleat.x) + parseInt(this.props.module.sx)/2} cy={parseInt(cleat.y) + parseInt(this.props.module.sy)/2} r="4" 
                                fill-opacity="0.1" strokeWidth="0.2" stroke="black" style={{cursor : "crosshair"}} 
                                directiony="1" directionx="0" />
                </g>
            </g>
            
          ))}
        </g>
    );
  }
 
}

export default InModuleView;