import React from 'react';

class TemplateOpenButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          imgName:"folder_white.png"
        };
    }

    onMouseOver() {
      this.setState({imgName:"folder_black.png"});
    }

    onMouseOut() {
      this.setState({imgName:"folder_white.png"});
    }

    onOpenTemplate(templateName) {
      fetch("templates/" + this.props.name)
      .then(response => response.json())
      .then(data => {
        this.props.onOpenClick(data);
      });
    }

    render(){
      return <img src={this.state.imgName} onMouseOver={()=>this.onMouseOver()} onMouseOut={()=>this.onMouseOut()}
                 onClick={()=>this.onOpenTemplate()} title="Open..." width="24px" height="24px" style={{cursor : "pointer", textAlign:"right"}}></img>
    }
}

export default TemplateOpenButton;
  