import React from 'react';
import toPng from './../../utils/toPng';
import { inject } from 'mobx-react';

var SavePng = inject("mainStore") (
    class SavePng extends React.Component {

    constructor(props) {
        super(props);

        this.onPngSave = this.onPngSave.bind(this);

        this.mainStore = this.props.mainStore;

        this.state = {
            imgName:"photo_white.png"
        };
    }

    onPngSave() {
        var min_x = Number.MAX_VALUE;
        var min_y = Number.MAX_VALUE;
        var max_x = -Number.MAX_VALUE;
        var max_y = -Number.MAX_VALUE;
        this.mainStore.modules.forEach((module)=>{
            min_x = Math.min(min_x, Number(module.x));
            min_y = Math.min(min_y, Number(module.y));
            max_x = Math.max(max_x, Number(module.x) + Number(module.sx));
            max_y = Math.max(max_y, Number(module.y) + Number(module.sy));
        });

        var svg = document.querySelector('svg');
        var serializer = new XMLSerializer();
        var svgString = serializer.serializeToString(svg.firstChild.firstChild);
        var width = (max_x - min_x + 100);
        var height = (max_y - min_y + 100);
        svgString = "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"" + (min_x - 50) + 
                                                                       " " + (min_y - 50) + 
                                                                       " " + (width) + 
                                                                       " " + (height) + "\">" + svgString + "</svg>";
        console.log(svgString);
        toPng({
            width: width*4,
            height: height*4,
            svg: svgString
        }).then((pngUrl) => {
            const link1 = document.createElement('a');
            link1.href = pngUrl;
            link1.setAttribute('download', 'electroschema.png');
            document.body.appendChild(link1);
            link1.click();
            document.body.removeChild(link1);
        })
    }

    onMouseOver() {
        this.setState({imgName:"photo_black.png"});
    }

    onMouseOut() {
        this.setState({imgName:"photo_white.png"});
    }

    render(){
        return <img src={this.state.imgName} onMouseOver={()=>this.onMouseOver()} onMouseOut={()=>this.onMouseOut()}
            onClick={this.onPngSave} title="Screenshot" width="48px" height="48px" style={{cursor : "pointer"}}></img>
    }
})

export default SavePng;



  
  
  