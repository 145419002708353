import React from 'react';
import { inject } from 'mobx-react';

var ToolbarInModuleView = inject("mainStore", "uiStore")(
    class ToolbarInModuleView extends React.Component {

    constructor(props) {
        super(props);

        this.mainStore = this.props.mainStore;
        this.uiStore = this.props.uiStore;

        this.onCleatSelectedColor = this.onCleatSelectedColor.bind(this);
    }

    onCleatSelectedColor (event) {
        this.setState({selectedColor: event.target.value});
        if (this.mainStore.currentModuleSelected >= 0) {
            this.mainStore.modules[this.mainStore.currentModuleSelected].cleats[Number(event.target.id)].color = JSON.parse(event.target.value);
            this.uiStore.refresh();
        }
    }

    render() {
        return (
            <fieldset>
                <legend>Свойства модуля</legend>
                {this.props.module.cleats.map((cleat, idx) => (
                    <label>Цвет:
                        <select class="colorSelect" onChange={this.onCleatSelectedColor} name={idx} id={idx} style={{width:"140px",margin:1} } 
                            value={cleat.color ? JSON.stringify(cleat.color) : ""}>
                            <option key="white" class="white" value="[0.9,0.9,0.9,1]">Белый</option>
                            <option key="red" class="red" value="[1,0,0,1]">Красный</option>
                            <option key="green" class="darkgreen" value="[0,0.5,0,1]">Зеленный</option>
                            <option key="blue" class="lightblue" value="[0,0.5,1,1]">Синий</option>
                            <option key="brown" class="brown" value="[0.545,0.27,0.074,1]">Коричневый</option>
                            <option key="yellow" class="yellow" value="[1,1,0,1]">Желтый</option>
                            <option key="black" class="black" value="[0.2,0.2,0.2,1]">Черный</option>
                        </select>
                    </label>
                ))}
            </fieldset>
        )
    }
})

export default ToolbarInModuleView;