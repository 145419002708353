import React from 'react';
import { inject } from 'mobx-react';

var ToolbarConnectorView = inject("mainStore", "uiStore")(
    class ToolbarConnectorView extends React.Component {

    constructor(props) {
        super(props);

        this.mainStore = this.props.mainStore;
        this.uiStore = this.props.uiStore;

        this.onColorSelected = this.onColorSelected.bind(this);
        this.onAlignmentSelected = this.onAlignmentSelected.bind(this);
        this.onPoleSelected = this.onPoleSelected.bind(this);
    }

    onColorSelected (event) {
        this.setState({colorSelected: event.target.value});
        if (this.mainStore.currentWireSelected.index >= 0 && this.mainStore.wires && this.mainStore.wires.length > 0) {
            this.mainStore.wires[this.mainStore.currentWireSelected.index].color = JSON.parse(event.target.value);
            this.uiStore.lastWireSelectedColor = this.mainStore.wires[this.mainStore.currentWireSelected.index].color;
            this.uiStore.refresh();
        }
    }

    onAlignmentSelected (event) {
        this.setState({alignmentSelected: event.target.value});
        if (this.mainStore.currentWireSelected.index >= 0 && this.mainStore.wires && this.mainStore.wires.length > 0) {
            this.mainStore.wires[this.mainStore.currentWireSelected.index].alignment = event.target.value;
            this.uiStore.refresh();
        }
    }

    onPoleSelected (event) {
        this.setState({poleSelected: event.target.value});
        if (this.mainStore.currentWireSelected.index >= 0 && this.mainStore.wires && this.mainStore.wires.length > 0) {
            this.mainStore.wires[this.mainStore.currentWireSelected.index].pole = event.target.value;
            this.uiStore.refresh();
        }
    }

    render() {
        return (
            <fieldset>
                <legend>Свойства коннектора</legend>
                <div>
                    <label>Цвет:
                    <select class="colorSelect" onChange={this.onColorSelected} name="Color" id="colorSelect" style={{width:"140px",margin:1} } 
                        value={this.props.wire && this.props.wire.color ? JSON.stringify(this.props.wire.color) : ""}>
                        <option key="white" class="white" value="[1,1,1,1]">Белый</option>
                        <option key="red" class="red" value="[1,0,0,1]">Красный</option>
                        <option key="green" class="green" value="[0,1,0,1]">Зеленный</option>
                        <option key="blue" class="blue" value="[0,0,1,1]">Синий</option>
                        <option key="brown" class="brown" value="[0.545,0.27,0.074,1]">Коричневый</option>
                        <option key="yellow" class="yellow" value="[1,1,0,1]">Желтый</option>
                        <option key="black" class="black" value="[0.3,0.3,0.3,1]">Черный</option>
                    </select>
                    </label>
                    { this.props.wire.type !== "Busbar" ?
                        <label>Выравнивание:
                        <select onChange={this.onAlignmentSelected} name="Alignment" id="alignmentSelect" style={{width:"140px",margin:1} } 
                            value={this.props.wire.alignment}>
                            <option key="direct" value="0">Кратчайший путь</option>
                            <option key="left" value="1">По левому краю</option>
                            <option key="right" value="2">По правому краю</option>
                            <option key="custom" value="3">В ручную</option>
                        </select>
                        </label>
                        :
                        <label>Количество полюсов:
                        <select onChange={this.onPoleSelected} name="Pole" id="poleSelect" style={{width:"140px",margin:1} } 
                            value={this.props.wire.pole}>
                            <option key="p1" value="1">1</option>
                            <option key="p2" value="2">2</option>
                            <option key="p3" value="3">3</option>
                            <option key="p4" value="4">4</option>
                        </select>
                        </label>
                    }
                </div>
            </fieldset>
        )
    }
})

export default ToolbarConnectorView;