import React from 'react';
import { inject } from 'mobx-react';

var OpenFile = inject("uiStore") (
  class OpenFile extends React.Component {

    constructor(props) {
        super(props);

        this.uiStore = this.props.uiStore;

        this.state = {
          imgName:"folder_white.png"
        };
    }

    onMouseOver() {
      this.setState({imgName:"folder_black.png"});
    }

    onMouseOut() {
      this.setState({imgName:"folder_white.png"});
    }

    render(){
      return <img src={this.state.imgName} onMouseOver={()=>this.onMouseOver()} onMouseOut={()=>this.onMouseOut()}
          onClick={()=>{this.uiStore.openPopup.show = !this.uiStore.openPopup.show}} title="Open..." width="48px" height="48px" style={{cursor : "pointer"}}></img>
    }
})

export default OpenFile;
  