
class MainStore {
    
    modules = [];
    
    wires = [];

    min = {x:Number.MAX_VALUE,y:Number.MAX_VALUE};
    max = {x:Number.MIN_VALUE,y:Number.MIN_VALUE};

    currentModuleSelected = -1;
    currentWireSelected = { index : -1,
                            point1 : 0,
                            point2 : 0,
                            isX : false};

    constructor() {

    }

    updateMinMax() {
        this.min.x = Number.MAX_VALUE;
        this.min.y = Number.MAX_VALUE;
        this.max.x = -Number.MAX_VALUE;
        this.max.y = -Number.MAX_VALUE;
        this.modules.forEach((module)=>{
            this.min.x = Math.min(this.min.x, Number(module.x));
            this.min.y = Math.min(this.min.y, Number(module.y));
            this.max.x = Math.max(this.max.x, Number(module.x) + Number(module.sx));
            this.max.y = Math.max(this.max.y, Number(module.y) + Number(module.sy));
        });
    }
}

const mainStore = new MainStore();

export default mainStore;