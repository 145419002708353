import React from 'react';
import ItemView from './ItemView';

class CatalogView extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        items: []
      };
    }

    render() {
      const { items } = this.state;
      if (items !== undefined && items.length>0) {
        return (
          <div className="catalog">
                {items.map((item) => (
                  <ItemView key={item} path={'/catalog' + this.props.path + '/' + item}/>
                ))}
          </div>
        )
      } 
    }

    componentDidMount() {
          fetch("/catalog" + this.props.path + "/items.json")
          .then(response => response.json())
          .then(data => {
            this.setState({ items: data.files });
          });
    }
}

export default CatalogView;