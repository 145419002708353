import React from 'react';
import TemplateOpenButton from './TemplateOpenButton';

class OpenTemplate extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
          templates: []
        };
    }

    render(){
      const { templates } = this.state;
      console.log(templates);
      if (templates !== undefined && templates.length>0) {
        return (
          <div style={{padding:"10px"}}>
            <table rules="row">
                {templates.map((template) => (
                  <tr style={{backgroundColor:"lightgray"}}>
                    <td style={{padding: "5px 10px 5px 5px", textAlign: "left"}}>{template}</td>
                    <td style={{textAlign: "center", verticalAlign: "middle"}}>
                      <TemplateOpenButton name={template} onOpenClick={this.props.onOpenClick}/>  
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        )
      } 
    }

    componentDidMount() {
      fetch('templates/items.json')
      .then(response => response.json())
      .then(data => {
        this.setState({ templates: data.files });
      });
    }
}

export default OpenTemplate;
  