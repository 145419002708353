import React from 'react';

class MenuMobile extends React.Component {

  constructor(props) {
        super(props);
        this.state = {
          imgName:"menu_white.png"
      };
  }

  onMouseOver() {
      this.setState({imgName:"menu_black.png"});
  }

  onMouseOut() {
      this.setState({imgName:"menu_white.png"});
  }

    render(){
      return <img src={this.state.imgName} onMouseOver={()=>this.onMouseOver()} onMouseOut={()=>this.onMouseOut()}
          onClick={this.props.onChange} title="Save" width="48px" height="48px" style={{cursor : "pointer"}}></img>
    }
}

export default MenuMobile;
  