import React from 'react';
import CatalogView from './CatalogView';
import Tabs from "./Tabs";

class CatalogsTabView extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        catalogs: []
      };
    }

    render() {
      const { catalogs } = this.state;
      console.log(catalogs);
      if (catalogs !== undefined && catalogs.length>0) {
        return (
          <div>
            <Tabs>
                {catalogs.map((catalog) => (
                  <div key={catalog.id} label={catalog.name}>
                      <CatalogView id={catalog.id} path={catalog.path} name={catalog.name}/>
                  </div>
                ))}
            </Tabs>
          </div>
        )
      } 
    }

    componentDidMount() {
          fetch('catalog/catalogs.json')
          .then(response => response.json())
          .then(data => {
            this.setState({ catalogs: data });
          });
    }
}

export default CatalogsTabView;