import './App.css';
import CatalogsTabView from './ui/catalog/CatalogsTabView'
import CanvasView from './ui/canvas/CanvasView';

function App() {
  return (
    <content>
      <CanvasView/>
      <footer> 
          <CatalogsTabView/>
      </footer>
    </content>
  );
}

export default App;
