import React from 'react';
import { inject, observer } from 'mobx-react';
import uuid from 'react-uuid'

var BoxPopup = inject("mainStore", "uiStore") (observer(
  class BoxPopup extends React.Component{
  
    constructor(props) {
        super(props);

        this.mainStore = this.props.mainStore;
        this.uiStore = this.props.uiStore;

        this.toggleBoxPopup = this.toggleBoxPopup.bind(this);

        this.onRowcountSelected = this.onRowcountSelected.bind(this);
        this.onDincountSelected = this.onDincountSelected.bind(this);
    }

    onRowcountSelected (event) {
        this.uiStore.boxPopup.rowcount = parseInt(event.target.value);
    }

    onDincountSelected (event) {
        this.uiStore.boxPopup.dincount = parseInt(event.target.value);
    }

    toggleBoxPopup() {
        if (this.uiStore.boxPopup.show) {
            var module = this.mainStore.modules[this.uiStore.boxPopup.index];
            module.rowcount = this.uiStore.boxPopup.rowcount;
            module.dincount = this.uiStore.boxPopup.dincount;

            module.sx = this.uiStore.boxPopup.dincount * 18 + 100;
            module.sy = this.uiStore.boxPopup.rowcount * 160 + 100;

            var y = module.sy / 2 - 130.0;
            module.cleats = Array();
            for(var i=0; i<this.uiStore.boxPopup.rowcount; i++) {
                var cleat = {id: uuid(), accept: "box", x:0, y: y}
                module.cleats = module.cleats.concat(cleat);
                y -= 160;
            }
            var busCount = Math.floor( (module.sx-24) / 12);
            var margin = (module.sx - busCount * 12) / 2;
            var x = - module.sx / 2 + margin + 6;
            for(var i=0; i < busCount-1; i++) {
                var cleat1 = {id: uuid(), accept: "bus", x:x, y: module.sy/2 - 25}
                module.cleats = module.cleats.concat(cleat1);
                var cleat2 = {id: uuid(), accept: "bus", x:x, y: -module.sy/2 + 25}
                module.cleats = module.cleats.concat(cleat2);
                x += 12;
            }

        }
        this.uiStore.boxPopup.show = !this.uiStore.boxPopup.show;
    }

    render() {
        return (
          <div className='popup'>
            <div className='popup_inner'>
              <table>
                <tr><td style={{textAlign: "center"}}>
                  <label>Количество рядов:
                      <select onChange={this.onRowcountSelected} name="Rowcount" id="rowcountSelect" style={{width:"140px",margin:1} } 
                          value={this.uiStore.boxPopup.rowcount}>
                          <option key="r1" value="1">1</option>
                          <option key="r2" value="2">2</option>
                          <option key="r3" value="3">3</option>
                          <option key="r4" value="4">4</option>
                      </select>
                  </label>
                  <label>Длина рядов:
                      <select onChange={this.onDincountSelected} name="Dincount" id="dincountSelect" style={{width:"140px",margin:1} } 
                          value={this.uiStore.boxPopup.dincount}>
                          <option key="d6" value="6">6</option>
                          <option key="d12" value="12">12</option>
                          <option key="d18" value="18">18</option>
                      </select>
                  </label>
                </td></tr>
                <tr><td style={{padding: "10px"}}>
                  <button style={{display: "block", margin:"auto", padding: "5px 20px" }} 
                      onClick={this.toggleBoxPopup}>Закрыть</button>
                </td></tr>
              </table>
            </div>
          </div>
        )
    }
}))

export default BoxPopup;

