import React from 'react';
import { inject, observer } from 'mobx-react';
import OpenTemplate from '../toolbar/OpenTemplate';

function buildFileSelector(){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    return fileSelector;
}

var OpenPopup = inject("mainStore", "uiStore") (observer(
    class OpenPopup extends React.Component{
  
    constructor(props) {
        super(props);

        this.mainStore = this.props.mainStore;
        this.uiStore = this.props.uiStore;

        this.onFileOpen = this.onFileOpen.bind(this);
        this.onLoad = this.onLoadFromEvent.bind(this);

        this.state = {
            isTemplate:false
        };

    }

    componentDidMount(){
        this.fileSelector = buildFileSelector();
        this.fileSelector.onchange=this.onFileOpen;
    }
      
    handleFileSelect = (e) => {
        this.state.isTemplate = false;
        e.preventDefault();
        this.fileSelector.click();
    }

    showTemplates() {
        this.setState({isTemplate:true});
    }

    loadFromJson(schema) {
        console.log(schema);
        this.mainStore.modules = [];
        schema.modules.forEach((module)=>{
            module.sx = module.size[0];
            module.sy = module.size[1];

            module.x =  module.position[0] - module.sx/2;
            module.y = -module.position[1] - module.sy/2;
            module.z =  module.position[2];

            module.cleats.forEach((cleat)=>{
                cleat.y=-cleat.y
                if (cleat.direction) {
                     cleat.direction[1] = -cleat.direction[1];
                }
            });
    
            this.mainStore.modules = this.mainStore.modules.concat(module);
        });
        this.mainStore.modules.sort((a,b) => Number(a.z) - Number(b.z));

        this.mainStore.wires = [];
        schema.connectors.forEach((connector)=>{
            connector.pt1.id = connector.pt1.cleatId;
            connector.pt2.id = connector.pt2.cleatId;
            var module1 = this.mainStore.modules[this.mainStore.modules.findIndex((module)=>module.cleats.find((cleat)=>cleat.id===connector.pt1.id))]; //getModuleIndexByCleatId
            var module2 = this.mainStore.modules[this.mainStore.modules.findIndex((module)=>module.cleats.find((cleat)=>cleat.id===connector.pt2.id))]; //getModuleIndexByCleatId
            var cleat1 = module1.cleats.find((cleat)=>cleat.id===connector.pt1.id);
            var cleat2 = module2.cleats.find((cleat)=>cleat.id===connector.pt2.id);
            
            connector.pt1.x = module1.x + cleat1.x + module1.sx/2;
            connector.pt1.y = module1.y + cleat1.y + module1.sy/2;
            connector.pt2.x = module2.x + cleat2.x + module2.sx/2;
            connector.pt2.y = module2.y + cleat2.y + module2.sy/2;

            connector.pt1.module = module1;
            connector.pt2.module = module2;

            connector.pt1.directionx = cleat1.direction ? cleat1.direction[0] : 0;
            connector.pt1.directiony = cleat1.direction ? cleat1.direction[1] : 0;

            connector.pt2.directionx = cleat2.direction ? cleat2.direction[0] : 0;
            connector.pt2.directiony = cleat2.direction ? cleat2.direction[1] : 0;

            this.mainStore.wires = this.mainStore.wires.concat(connector);
        });
        this.mainStore.currentModuleSelected = -1;
        this.mainStore.updateMinMax();
        this.uiStore.offsetX = -this.mainStore.min.x * this.uiStore.scale + 100;
        this.uiStore.offsetY = -this.mainStore.min.y * this.uiStore.scale + 100
    }

    onLoadFromEvent(event) {
        var schema = JSON.parse(event.target.result);
        this.loadFromJson(schema);
    }

    onFileOpen(event) {
        if (event.target) {
            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = this.onLoad; //Don't change!!!!!
            reader.readAsText(file);
        } else {
            this.loadFromJson(event);
        }

        this.uiStore.openPopup.show = false;
        this.uiStore.refresh();
    }

    render() {
        return (
          <div className='popup'>
            <div className='popup_inner'>
                <table>
                    <tr><td style={{textAlign: "center"}}>
                    {this.state.isTemplate ? 
                        <OpenTemplate onOpenClick={this.onFileOpen}/>
                        :
                        <div>
                            <table>
                            <tr><td style={{width:"120px", padding: "15px"}}>
                            <div onClick={this.handleFileSelect}
                                style={{border : "lightgray", backgroundColor:"lightgray", borderRadius: "7px", cursor : "pointer", boxShadow: "10px 5px 5px gray", textAlign:"center"}}>
                                    <img src="hard_drive.png" title="Open file..." width="48px" height="48px" style={{cursor : "pointer", paddingTop:"10px", textAlign:"center"}}></img>
                                        <p style={{textAlign:"center", paddingBottom:"10px"}}>Файл</p>
                            </div>
                            </td>
                            <td style={{width:"120px", padding: "15px"}}>
                            <div onClick={()=>{this.showTemplates()}}
                                style={{border : "lightgray", backgroundColor:"lightgray", borderRadius: "7px", cursor : "pointer", boxShadow: "10px 5px 5px gray", textAlign:"center"}}>
                                    <img src="template.png" title="Open template..." width="48px" height="48px" style={{cursor : "pointer", paddingTop:"10px", textAlign:"center"}}></img>
                                    <p style={{textAlign:"center", paddingBottom:"10px"}}>Шаблон</p>
                            </div>
                            </td></tr>
                            </table>
                        </div>
                    }
                    </td></tr>
                    <tr><td style={{padding: "10px"}}>
                        <button style={{display: "block", margin:"auto", padding: "5px 20px" }} onClick={()=>{this.uiStore.openPopup.show = !this.uiStore.openPopup.show}}>Отменить</button>
                    </td></tr>
                </table>
            </div>
          </div>
        )
    }
}))

export default OpenPopup;

