import React from 'react';
import BoxModuleView from './BoxModuleView.js';
import InModuleView from './InModuleView.js';

class ModuleView extends React.Component {

  render() {
      let text_x = 0;
      let text_y = 0;
      let text = "";
      if (this.props.module.texts && this.props.module.textIndex!==undefined && this.props.module.textIndex>=0) {
        text = this.props.module.texts[this.props.module.textIndex].text;
        text_x = Number(this.props.module.texts[this.props.module.textIndex].x) * 0.2;
        text_y = Number(this.props.module.texts[this.props.module.textIndex].y) * 0.222;
      }
//class="small"
//      this.props.module.cleats.filter((cleat) => (cleat.accept === "in" || cleat.accept === "out")).map((cleat) => (
//            console.log(cleat.direction[1])
//      ));

   return (
      this.props.module.catalog==="BOX" ? <BoxModuleView key={this.props.key} x={this.props.x} y={this.props.y} id={this.props.id} module={this.props.module}/> :
      this.props.module.catalog==="IN" ? <InModuleView key={this.props.key} x={this.props.x} y={this.props.y} id={this.props.id} module={this.props.module}/> :
      <g id={this.props.id} transform={"translate(" + this.props.x + "," + this.props.y +")"}>
          <image image={this.props.module.id} draggable="false" x="0" y="0" 
                width={this.props.module.sx} height={this.props.module.sy} 
                preserveAspectRatio="none" href={"/catalog/Textures/" + this.props.module.preview}/>

          {this.props.module.color && this.props.module.catalog !== "DIN" && this.props.module.catalog !== "BUS" && this.props.module.catalog !== "IN" ?
            <line x1="0.4" y1="24.5" 
                  x2={this.props.module.sx-0.4} y2="24.5" 
                  strokeWidth="4" stroke={"rgb(" + this.props.module.color[0] * 255 + "," +  this.props.module.color[1] * 255 + "," + this.props.module.color[2] * 255 + ")"}/>
          :""}

          <text x={text_x} y={text_y} font-size="0.4em" font-family="sans-serif">{text}</text>

          {this.props.module.cleats.filter((cleat) => (cleat.accept === "in" || cleat.accept === "out")).map((cleat) => (
            <g id={this.props.id}>
              <line x1={parseInt(cleat.x) + parseInt(this.props.module.sx)/2 - 4} y1={parseInt(cleat.y) + parseInt(this.props.module.sy)/2} 
                    x2={parseInt(cleat.x) + parseInt(this.props.module.sx)/2 + 4} y2={parseInt(cleat.y) + parseInt(this.props.module.sy)/2} 
                    strokeWidth="1.4" stroke="rgb(20,20,20)"/>
              <line x1={parseInt(cleat.x) + parseInt(this.props.module.sx)/2} y1={parseInt(cleat.y) + parseInt(this.props.module.sy)/2 - 4} 
                    x2={parseInt(cleat.x) + parseInt(this.props.module.sx)/2} y2={parseInt(cleat.y) + parseInt(this.props.module.sy)/2 + 4}
                    strokeWidth="1.4" stroke="rgb(20,20,20)"/>
              <line x1={parseInt(cleat.x) + parseInt(this.props.module.sx)/2 - 4} y1={parseInt(cleat.y) + parseInt(this.props.module.sy)/2} 
                    x2={parseInt(cleat.x) + parseInt(this.props.module.sx)/2 + 4} y2={parseInt(cleat.y) + parseInt(this.props.module.sy)/2} 
                    strokeWidth="1" stroke="rgb(170,170,170)"/>
              <line x1={parseInt(cleat.x) + parseInt(this.props.module.sx)/2} y1={parseInt(cleat.y) + parseInt(this.props.module.sy)/2 - 4} 
                    x2={parseInt(cleat.x) + parseInt(this.props.module.sx)/2} y2={parseInt(cleat.y) + parseInt(this.props.module.sy)/2 + 4}
                    strokeWidth="1" stroke="rgb(170,170,170)"/>
              <circle key={cleat.id} id={cleat.id} cx={parseInt(cleat.x) + parseInt(this.props.module.sx)/2} cy={parseInt(cleat.y) + parseInt(this.props.module.sy)/2} r="4" 
                      fill-opacity="0.1" strokeWidth="0.2" stroke="black" style={{cursor : "crosshair"}} 
                      directiony={cleat.direction && cleat.direction[0]===1 ? 0 : (cleat.direction && cleat.direction[1]!==0 ? cleat.direction[1] : Math.sign(parseInt(cleat.y)))} 
                      directionx={cleat.direction ? cleat.direction[0] : 0} />
            </g>
          ))}

        </g>
      );
  }
 
}

export default ModuleView;